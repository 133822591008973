<template>
	<div>
		<div class="veadmin-iframe-header veadmin-iframe-header-custom-fixed">
			<span>当前位置:</span>
			企业形象<span>&gt;</span>
			关于我们
			<button class="veadmin-iframe-button" onclick="location.reload();">
				<i class="el-icon-refresh-right"></i>
			</button>
		</div>
		<div class="veadmin-iframe-content veadmin-iframe-content-custom-fixed">
			<el-form :model="history" status-icon ref="elForm" label-width="20px" style="margin-top:10px;">
				<el-form-item label="" prop="text">
					<div id="wangEditor" style="margin-right:20px;"></div>
				</el-form-item>
				<el-form-item>
					<el-button type="primary" @click="saveHistory">保存</el-button>
				</el-form-item>
			</el-form>
		</div>
	</div>
</template>

<script>
// 引入 wangEditor
import wangEditor from "wangeditor";
import { apiGetHistory } from "@/api/pub/history.js";
import { apiUpdateHistory } from "@/api/admin/history.js";
import base from "@/base.js";

export default {
	data() {
		return {
			apiServer: base.apiServer,
			history: {
				id: "",
				text: ""
			}
		};
	},
	methods: {
		saveHistory() {
			if (this.history.text == "") {
				this.$message.error("请输入内容");
				return;
			}
			//console.log(this.history);
			//不需要上传图片文件
			let formData = new FormData();
			formData.append("id", this.history.id);
			formData.append("text", this.history.text);
			/* for (let [a, b] of formData.entries()) {
					console.log(a + ":", b || '""');
				} */
			apiUpdateHistory(this.$store.state.token, formData).then((response) => {
				if (response.data.code == 200) {
					this.$message({ message: "保存成功", type: "success" });
					//执行成功1秒后刷新页面
					setTimeout(function() {
						location.reload();
					}, 1000);
				} else if (response.data.code == -100) {
					this.$confirm(response.data.message + "。将跳转至登录页面, 是否继续?", "提示", {
						confirmButtonText: "确定",
						cancelButtonText: "取消",
						type: "error"
					})
						.then(() => {
							//跳转到登录页面（路由跳转则滑动验证会失效）
							//this.$router.push({ path: "/login?target=" + window.location.href });
							window.location.href = "/login?target=" + window.location.href;
						})
						.catch(() => {
							this.$message({
								type: "warning",
								message: "已取消"
							});
						});
				} else {
					console.log("response>>", response);
					this.$message.error(response.data.message);
				}
			});
		},
		async getHistory() {
			try {
				const response = await apiGetHistory();
				//console.log(response);
				if (response.data.code == 200) {
					let history = response.data.data;
					if (history == null) {
						this.submitElUpload = true;
					} else {
						this.submitElUpload = false;
						this.history = history;
						this.editor.txt.html(this.history.text);
					}
				} else {
					this.$message.error(response.data.message);
				}
			} catch (error) {
				console.log(error);
			}
		}
	},
	mounted() {
		//生成编辑器
		const editor = new wangEditor("#wangEditor");
		//上传文件的名字
		editor.config.uploadFileName = "image";
		// 配置 server 接口地址
		editor.config.uploadImgServer = base.apiServer + "/api/v1/admin/history/upload_image";
		// 1M
		editor.config.uploadImgMaxSize = 1024 * 1024;
		//限制上传格式
		editor.config.uploadImgAccept = ["jpg", "jpeg", "png", "gif", "bmp"];
		// 一次最多上传 5 个图片
		editor.config.uploadImgMaxLength = 5;
		//设置headers
		editor.config.uploadImgHeaders = { token: this.$store.state.token };
		//设置z-index
		editor.config.zIndex = 500;
		//默认编辑器占高300px,设置为320
		editor.config.height = 320;
		// 配置 onchange 回调函数，将数据同步到 vue 中
		editor.config.onchange = (newHtml) => {
			//console.log(newHtml);
			this.history.text = newHtml;
		};
		editor.config.placeholder = "请输入内容";
		// 创建编辑器
		editor.create();
		this.editor = editor;
		console.log(this.editor);

		this.getHistory();
	}
};
</script>
<style lang="scss"></style>
<style lang="scss" scoped>
.upload-demo {
	width: 297px;
}
</style>
